import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Fade from 'react-reveal/Fade';

import Banner from "../components/sections/banner"
import Footer from "../components/sections/footer"

const IndexPage = () => (
  <Layout>
    <SEO title="Thank you for registering" />
    <Navigation />
    <Banner>
      <h1>Thank you!</h1>
      <p>We're onboarding beta users soon, we'll be in touch shortly!</p>
    </Banner>
    <Footer />
  </Layout>
)

export default IndexPage
